import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Home from "./components/home/home.jsx"
import PDFViewer from "./components/pdfViewer/pdfViewer.jsx"

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/disposicionHabilitante" element={<PDFViewer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
