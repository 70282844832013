import React from "react";
import "./home.css";
import Welcome from "../welcome/welcome.jsx";
import FirstParagraph from "../firstParagraph/firstParagraph.jsx";
import SecondParagraph from "../secondParagraph/secondParagraph.jsx"
import Body from "../body/body.jsx";
import Footer from "../footer/footer.jsx";

export default function Home() {
    return (
        <div className="home">
            <Welcome />
            <FirstParagraph />
            <SecondParagraph />
            <Body />
            <Footer />
        </div>
    )
}