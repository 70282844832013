import React from "react";
import "./secondParagraph.css";
import mobilePic1 from "../../imgs/mobilePic1.svg";
import mobilePic2 from "../../imgs/mobilePic2.svg";
import mobilePic3 from "../../imgs/mobilePic3.svg";
export default function SecondParagraph() {
    return (
        <section className="second-paragraph">
            <img className="img1" src={mobilePic1} alt="mobile" />
            <img className="img2" src={mobilePic2} alt="mobile" />
            <img className="img3" src={mobilePic3} alt="mobile" />
            <a href="https://baldoniviajes.com/" target="_blank"><p className="btnWebSecond">www.baldoniviajes.com</p></a>
        </section >
    )
}