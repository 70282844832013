import React from "react";
import emailjs from "@emailjs/browser";

import "./footer.css";
import facebook from "../../icons/facebook.svg";
import instagram from "../../icons/instagram.svg";
import wsp from "../../icons/wsp.svg";

export default function Footer() {

  function onSubmit(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_o075ahq",
        "template_o2t1yio",
        e.target,
        "TUUMwl5rW9meT32Bu"
      )
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  }

  return (
    <section className="footer">
      <div className="rrss">
        <a
          href="https://www.facebook.com/baldoniviajes"
          target="_blank"
          className="button"
          rel="noopener noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/baldoniviajesok/"
          target="_blank"
          className="button"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="instagrma" />
        </a>
        <a
          href="https://wa.me/542477#"
          target="_blank"
          className="button"
          rel="noopener noreferrer"
        >
          <img src={wsp} alt="wsp" />
        </a>
      </div>

      <div className="informacion">
        <span className="spanInfo">
          <p className="titleForm">SOMOS BALDONI VIAJES</p>

          <p className="numeroPermiso">DIRECCION:</p>
          <p className="numeroPermiso">
            E-MAIL: <br />
            baldoniviajes@gmail.com
          </p>
          <p className="numeroPermiso">
            N° de expediente: <br />
            PV-2022-136384701-APN-DRAV#MTYD
          </p>
          <p className="numeroPermiso">
            Razón Social: <br />
            Baldoni Turismo de Baldoni Juan Ignacio
          </p>
        </span>

        <span className="qrData">
          <span>
            <a
              href="http://qr.afip.gob.ar/?qr=wKRT9ePj7uoXQO2AuoX5_w,,"
              target="_F960AFIPInfo"
            >
              <img
                src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                border="0"
                alt="QR"
              />
            </a>
          </span>
        </span>
      </div>

      <form className="formulario" id="contacto" onSubmit={onSubmit}>
        <p className="titleForm2">¡HACE TU CONSULTA!</p>
        <div className="inputsDatos">
          <div className="combo1">
            <div className="inputNombre">
              <label>NOMBRE Y APELLIDO</label>
              <input
                type="text"
                name="user_name"
                required
                placeholder="Nombre Apellido"
              />
            </div>

            <div className="inputEmail">
              <label>E-MAIL:</label>
              <input
                type="email"
                name="user_email"
                required
                placeholder="baldoniviajes@gmail.com"
              />
            </div>
          </div>

          <div className="combo2">
            <div className="inputNumero">
              <label>TELEFONO</label>
              <input
                type="text"
                name="user_number"
                placeholder="Formato: (123) 123-4567"
                required
              />
            </div>

            <div className="inputCiudad">
              <label>CIUDAD</label>
              <input
                type="text"
                name="user_ciudad"
                required
                placeholder="Pergamino"
              />
            </div>
          </div>

          <div className="textArea">
            <label>MENSAJE</label>
            <textarea placeholder="..." name="user_message" required />
          </div>
        </div>

        <button className="buttonSend button" type="submit">
          Enviar
        </button>
      </form>

      <div className="documentacion">
        <a href="https://baldoniviajes.com/" target="_blank" rel="noreferrer">
          <p>Local virtual</p>
        </a>
        <p>/</p>
        <p>N° de legajo: 18489</p>
        <p>/</p>
        <p>Resolución N° 366</p>
        <p>/</p>
        <a
          href="https://www.argentina.gob.ar/servicio/presentar-una-denuncia-contra-una-agencia-de-viajes"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Denuncia contra una agencia</p>
        </a>
        <p>/</p>
        <p>Designación comercial</p>
        <p>/</p>
        <a
          href="static/media/disposicionHabilitante.d87c8a477e60b2e2adf5.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p> Disposición habilitante</p>
        </a>
      </div>
    </section>
  );
}
