import React, { useRef, useEffect } from "react";
import "./welcome.css";
import bienvenidos from "../../imgs/bienvenidos.svg";
import logoCompleto from "../../imgs/logoCompleto.svg";
import iconLogo from "../../imgs/iconLogo.svg";
import video from "../../imgs/videoWelcome.mp4";

export default function Welcome() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleVideoTimeUpdate = () => {
      const pauseTime = 32; // El tiempo en segundos en el cual deseas pausar el video

      if (video.currentTime >= pauseTime) {
        video.pause();
        setTimeout(() => {
          video.style.opacity = 0;
          setTimeout(() => {
            video.classList.replace("video", "video-pos");
            video.style.opacity = 1;
            document.getElementById("btnCloseVideo").style.display = "none";
          }, 1000); // Retraso de 10 milisegundos antes de cambiar las clases
        }, 1000); // Retraso de 10 milisegundos antes de cambiar la opacidad
      }
    };

    video.addEventListener("timeupdate", handleVideoTimeUpdate);

    return () => {
      video.removeEventListener("timeupdate", handleVideoTimeUpdate);
    };
  }, []);

  function handleCloseVideo() {
    const video = videoRef.current;
    video.classList.replace("video", "video-pos");
    document.getElementById("btnCloseVideo").style.display = "none";
  }

  return (
    <div className="bodyWelcome">
      <section className="navBar">
        <div className="square1">
          <img src={iconLogo} alt="iconLogo" />
        </div>
        <a href="#nosotros" className="square2 button">
          <p>NOSOTROS</p>
        </a>
        <a href="#servicios" className="square3 button">
          <p>SERVICIOS</p>
        </a>
        <a href="#contacto" className="square4 button">
          <p>CONTACTO</p>
        </a>
      </section>
      <section className="welcomeView">
        <video ref={videoRef} className="video hoverSee" autoPlay muted>
          <source src={video} type="video/mp4" />
        </video>

        <button
          id="btnCloseVideo"
          onClick={handleCloseVideo}
          className="saltarVideo buttonVideo"
        >
          X
        </button>

        <img className="bienvenidos" src={bienvenidos} alt="bienvenidos" />

        <img className="logoCompleto" src={logoCompleto} alt="logoCompleto" />

        <button className="btn1 button">
          <a href="https://baldoniviajes.com/" target="_blank">
            VISITA NUESTRA TIENDA
          </a>
        </button>

        <div className="sizeButtons doubleButton">
          <button className="button">
            <a href="#contacto">CONTACTANOS</a>
          </button>
          <button className="button">
            <a href="#nosotros">NOSOTROS</a>
          </button>
        </div>
      </section>
    </div>
  );
}
