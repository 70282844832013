import React from "react";
import "./firstParagraph.css";
import nuestraTienda from "../../imgs/nuestraTienda.svg";

export default function FirstParagraph() {
    return (
        <section className="first-paragraph">
            <img src={nuestraTienda} alt="nuestra tienda" />
            <p className="btnWeb"><a href="https://baldoniviajes.com/" target="_blank">www.baldoniviajes.com</a></p>
            <p className="first-paragraph-text">Ingresá y reserva tu próxima salida
                sin moverte de tu casa.</p>
        </section>
    )
}