import React from 'react';

class PDFViewer extends React.Component {

    componentDidMount() {
        if (typeof window.orientation !== "undefined") {
            document.getElementById("enlaceDescargaPdf").click();
            window.close();
        }
    }
    render() {
        return (
            <div style={{ position: "absolute", width: "100%", height: "100%" }}>
                <object
                    data={require("../../assets/disposicionHabilitante.pdf")}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                >
                    <br />
                    <a href={require("../../assets/disposicionHabilitante.pdf")} id="enlaceDescargaPdf" download="DisposicionHabilitante.pdf"></a>
                    <a>Tu dispositivo no puede visualizar PDF, da click aqui para descargar</a>
                </object>
            </div>
        );
    }
}

export default PDFViewer;
