import React from "react";
import "./body.css";
import subTitle1 from "../../imgs/subTitle1.svg";
import subTitle2 from "../../imgs/subTitle2.svg";

export default function Body() {
  return (
    <section id="nosotros" className="bodyGeneral">
      <div className="firstText" id="nosotros">
        <img src={subTitle1} alt="subTitle1" />

        <p>
          Tres generaciones que con esfuerzo, dedicación y una gran vocación
          hacia el servicio lograron posicionar esta empresa familiar como una
          de las mas importantes de la región.
          <br />
          Baldoni viajes cuenta con mas de 40 años de trayectoria y experiencia
          prestando el servicio de transporte de pasajeros mediante viajes
          especiales, turísticos, y contratados con todas las habilitaciones
          correspondientes.
          <br />
          Hoy contamos con gran cantidad de modernas unidades, con los mejores
          estándares de calidad, equipadas según lo requieren las normas de
          seguridad, planificadas con diferentes capacidades, que le dan al
          cliente una versatilidad total para la contratación de los diferentes
          servicios.
          <br />
        </p>
      </div>

      <div id="servicios" className="bubblesImg">
        <div className="firstBubble bubblesContainer">
          <span className="img1"></span>
          <p className="titleBubble">TRANSPORTE</p>
          <p className="textBubble">
            PARA CLUBES Y <br /> EQUIPOS
          </p>
        </div>

        <div className="secondBubble bubblesContainer">
          <span className="img2"></span>
          <p className="titleBubble ">TRASLADOS</p>
          <p className="textBubble">
            EXPRESARIALES Y <br /> EJECUTIVOS
          </p>
        </div>

        <div className="thirdBubble bubblesContainer">
          <span className="img3"></span>
          <p className="titleBubble">TRASLADOS</p>
          <p className="textBubble">
            TOUR DE
            <br /> COMPRAS
          </p>
        </div>
      </div>

      <div className="secondText">
        <div className="thirdBubble bubblesContainer containerImg-secondText">
          <span className="img4"></span>
        </div>

        <div className="second-text">
          <img src={subTitle2} alt="subTitle2" />

          <p>
            Combinamos conocimientos y capacidades para proveer la excelencia en
            el servicio, junto con la responsabilidad y el compromiso que nos
            caracteriza. Nuestros pilares fundamentales son la calidad, la
            seguridad y la eficiencia, lo que nos lleva a mejoras concretas y
            continuas que nos convierten en una de las mejores opciones para
            llevar a cabo el traslado de pasajeros.
          </p>
        </div>
      </div>

      <div className="weAre">
        <p>Somos trayectoria.</p>
        <p>Somos movimiento.</p>
        <p>Somos Baldoni Viajes.</p>
      </div>
    </section>
  );
}
